exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-and-tax-js": () => import("./../../../src/pages/accounting-and-tax.js" /* webpackChunkName: "component---src-pages-accounting-and-tax-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-back-in-business-js": () => import("./../../../src/pages/back-in-business.js" /* webpackChunkName: "component---src-pages-back-in-business-js" */),
  "component---src-pages-bankruptcy-js": () => import("./../../../src/pages/bankruptcy.js" /* webpackChunkName: "component---src-pages-bankruptcy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-liquidation-brisbane-js": () => import("./../../../src/pages/company-liquidation-brisbane.js" /* webpackChunkName: "component---src-pages-company-liquidation-brisbane-js" */),
  "component---src-pages-company-liquidation-melbourne-js": () => import("./../../../src/pages/company-liquidation-melbourne.js" /* webpackChunkName: "component---src-pages-company-liquidation-melbourne-js" */),
  "component---src-pages-company-liquidation-perth-js": () => import("./../../../src/pages/company-liquidation-perth.js" /* webpackChunkName: "component---src-pages-company-liquidation-perth-js" */),
  "component---src-pages-company-liquidation-sydney-js": () => import("./../../../src/pages/company-liquidation-sydney.js" /* webpackChunkName: "component---src-pages-company-liquidation-sydney-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-advisory-js": () => import("./../../../src/pages/corporate-advisory.js" /* webpackChunkName: "component---src-pages-corporate-advisory-js" */),
  "component---src-pages-corporate-insolvency-landing-page-js": () => import("./../../../src/pages/corporate-insolvency-landing-page.js" /* webpackChunkName: "component---src-pages-corporate-insolvency-landing-page-js" */),
  "component---src-pages-creditor-portal-js": () => import("./../../../src/pages/creditor-portal.js" /* webpackChunkName: "component---src-pages-creditor-portal-js" */),
  "component---src-pages-cyber-security-js": () => import("./../../../src/pages/cyber-security.js" /* webpackChunkName: "component---src-pages-cyber-security-js" */),
  "component---src-pages-deal-hub-js": () => import("./../../../src/pages/deal-hub.js" /* webpackChunkName: "component---src-pages-deal-hub-js" */),
  "component---src-pages-debt-agreements-js": () => import("./../../../src/pages/debt-agreements.js" /* webpackChunkName: "component---src-pages-debt-agreements-js" */),
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-director-penalty-notice-js": () => import("./../../../src/pages/director-penalty-notice.js" /* webpackChunkName: "component---src-pages-director-penalty-notice-js" */),
  "component---src-pages-do-not-give-up-landing-page-js": () => import("./../../../src/pages/do-not-give-up-landing-page.js" /* webpackChunkName: "component---src-pages-do-not-give-up-landing-page-js" */),
  "component---src-pages-doca-js": () => import("./../../../src/pages/doca.js" /* webpackChunkName: "component---src-pages-doca-js" */),
  "component---src-pages-dpn-landing-page-js": () => import("./../../../src/pages/dpn-landing-page.js" /* webpackChunkName: "component---src-pages-dpn-landing-page-js" */),
  "component---src-pages-event-and-sales-coordinator-js": () => import("./../../../src/pages/event-and-sales-coordinator.js" /* webpackChunkName: "component---src-pages-event-and-sales-coordinator-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-new-js": () => import("./../../../src/pages/events-new.js" /* webpackChunkName: "component---src-pages-events-new-js" */),
  "component---src-pages-expertsinbusiness-js": () => import("./../../../src/pages/expertsinbusiness.js" /* webpackChunkName: "component---src-pages-expertsinbusiness-js" */),
  "component---src-pages-graduate-insolvency-analyst-brisbane-js": () => import("./../../../src/pages/graduate-insolvency-analyst-brisbane.js" /* webpackChunkName: "component---src-pages-graduate-insolvency-analyst-brisbane-js" */),
  "component---src-pages-graduate-insolvency-analyst-melbourne-js": () => import("./../../../src/pages/graduate-insolvency-analyst-melbourne.js" /* webpackChunkName: "component---src-pages-graduate-insolvency-analyst-melbourne-js" */),
  "component---src-pages-graduate-insolvency-analyst-sydney-js": () => import("./../../../src/pages/graduate-insolvency-analyst-sydney.js" /* webpackChunkName: "component---src-pages-graduate-insolvency-analyst-sydney-js" */),
  "component---src-pages-identifix-js": () => import("./../../../src/pages/identifix.js" /* webpackChunkName: "component---src-pages-identifix-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insolvency-accountant-js": () => import("./../../../src/pages/insolvency-accountant.js" /* webpackChunkName: "component---src-pages-insolvency-accountant-js" */),
  "component---src-pages-insolvency-intermediate-analyst-melbourne-js": () => import("./../../../src/pages/insolvency-intermediate-analyst-melbourne.js" /* webpackChunkName: "component---src-pages-insolvency-intermediate-analyst-melbourne-js" */),
  "component---src-pages-insolvency-intermediate-analyst-sydney-js": () => import("./../../../src/pages/insolvency-intermediate-analyst-sydney.js" /* webpackChunkName: "component---src-pages-insolvency-intermediate-analyst-sydney-js" */),
  "component---src-pages-insolvency-js": () => import("./../../../src/pages/insolvency.js" /* webpackChunkName: "component---src-pages-insolvency-js" */),
  "component---src-pages-liquidation-js": () => import("./../../../src/pages/liquidation.js" /* webpackChunkName: "component---src-pages-liquidation-js" */),
  "component---src-pages-liquidation-landing-page-js": () => import("./../../../src/pages/liquidation-landing-page.js" /* webpackChunkName: "component---src-pages-liquidation-landing-page-js" */),
  "component---src-pages-media-moments-js": () => import("./../../../src/pages/media-moments.js" /* webpackChunkName: "component---src-pages-media-moments-js" */),
  "component---src-pages-mg-academy-js": () => import("./../../../src/pages/mg-academy.js" /* webpackChunkName: "component---src-pages-mg-academy-js" */),
  "component---src-pages-mg-way-js": () => import("./../../../src/pages/mg-way.js" /* webpackChunkName: "component---src-pages-mg-way-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-personal-insolvency-js": () => import("./../../../src/pages/personal-insolvency.js" /* webpackChunkName: "component---src-pages-personal-insolvency-js" */),
  "component---src-pages-project-8-js": () => import("./../../../src/pages/project-8.js" /* webpackChunkName: "component---src-pages-project-8-js" */),
  "component---src-pages-receivership-js": () => import("./../../../src/pages/receivership.js" /* webpackChunkName: "component---src-pages-receivership-js" */),
  "component---src-pages-receivership-landing-page-js": () => import("./../../../src/pages/receivership-landing-page.js" /* webpackChunkName: "component---src-pages-receivership-landing-page-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-research-and-development-js": () => import("./../../../src/pages/research-and-development.js" /* webpackChunkName: "component---src-pages-research-and-development-js" */),
  "component---src-pages-restructure-turnaround-landing-page-js": () => import("./../../../src/pages/restructure-turnaround-landing-page.js" /* webpackChunkName: "component---src-pages-restructure-turnaround-landing-page-js" */),
  "component---src-pages-restructuring-js": () => import("./../../../src/pages/restructuring.js" /* webpackChunkName: "component---src-pages-restructuring-js" */),
  "component---src-pages-safe-harbour-js": () => import("./../../../src/pages/safe-harbour.js" /* webpackChunkName: "component---src-pages-safe-harbour-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-senior-analyst-insolvency-manager-js": () => import("./../../../src/pages/senior-analyst-insolvency-manager.js" /* webpackChunkName: "component---src-pages-senior-analyst-insolvency-manager-js" */),
  "component---src-pages-senior-insolvency-analyst-melbourne-js": () => import("./../../../src/pages/senior-insolvency-analyst-melbourne.js" /* webpackChunkName: "component---src-pages-senior-insolvency-analyst-melbourne-js" */),
  "component---src-pages-small-business-restructure-adelaide-js": () => import("./../../../src/pages/small-business-restructure-adelaide.js" /* webpackChunkName: "component---src-pages-small-business-restructure-adelaide-js" */),
  "component---src-pages-small-business-restructure-in-brisbane-js": () => import("./../../../src/pages/small-business-restructure-in-brisbane.js" /* webpackChunkName: "component---src-pages-small-business-restructure-in-brisbane-js" */),
  "component---src-pages-small-business-restructure-js": () => import("./../../../src/pages/small-business-restructure.js" /* webpackChunkName: "component---src-pages-small-business-restructure-js" */),
  "component---src-pages-small-business-restructure-landing-page-js": () => import("./../../../src/pages/small-business-restructure-landing-page.js" /* webpackChunkName: "component---src-pages-small-business-restructure-landing-page-js" */),
  "component---src-pages-small-business-restructure-melbourne-js": () => import("./../../../src/pages/small-business-restructure-melbourne.js" /* webpackChunkName: "component---src-pages-small-business-restructure-melbourne-js" */),
  "component---src-pages-small-business-restructure-perth-js": () => import("./../../../src/pages/small-business-restructure-perth.js" /* webpackChunkName: "component---src-pages-small-business-restructure-perth-js" */),
  "component---src-pages-small-business-restructure-sydney-js": () => import("./../../../src/pages/small-business-restructure-sydney.js" /* webpackChunkName: "component---src-pages-small-business-restructure-sydney-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-vcfo-js": () => import("./../../../src/pages/vcfo.js" /* webpackChunkName: "component---src-pages-vcfo-js" */),
  "component---src-pages-voluntary-administration-js": () => import("./../../../src/pages/voluntary-administration.js" /* webpackChunkName: "component---src-pages-voluntary-administration-js" */),
  "component---src-pages-voluntary-administration-landing-page-js": () => import("./../../../src/pages/voluntary-administration-landing-page.js" /* webpackChunkName: "component---src-pages-voluntary-administration-landing-page-js" */),
  "component---src-pages-watch-on-demand-js": () => import("./../../../src/pages/watch-on-demand.js" /* webpackChunkName: "component---src-pages-watch-on-demand-js" */),
  "component---src-pages-workinglunch-js": () => import("./../../../src/pages/workinglunch.js" /* webpackChunkName: "component---src-pages-workinglunch-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

