/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// in gastby-browser.js
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  // const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
  // if the new route is part of the list above, scroll to top (0, 0)
  window.scrollTo(0, 0);
  return false;
};

(function () {
  let queryParams = [
    "utm_medium", //add or remove query parameters you want to transfer
    "utm_source",
    "utm_campaign",
    "something_else",
  ];
  // do not edit anything below this line
  var links = document.querySelectorAll("a");

  // check if links contain domain from the domainsToDecorate array and then decorates
  for (var linkIndex = 0; linkIndex < links.length; linkIndex++) {
    //for (var domainIndex = 0; domainIndex < domainsToDecorate.length; domainIndex++) {
    //if (links[linkIndex].href.indexOf(domainsToDecorate[domainIndex]) > -1 && links[linkIndex].href.indexOf("#") === -1) {
    links[linkIndex].href = decorateUrl(links[linkIndex].href);
    //}
    //}
  }
  // decorates the URL with query params
  function decorateUrl(urlToDecorate) {
    urlToDecorate =
      urlToDecorate.indexOf("?") === -1
        ? urlToDecorate + "?"
        : urlToDecorate + "&";
    var collectedQueryParams = [];
    for (var queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
      if (getQueryParam(queryParams[queryIndex])) {
        collectedQueryParams.push(
          queryParams[queryIndex] +
            "=" +
            getQueryParam(queryParams[queryIndex]),
        );
      }
    }
    return urlToDecorate + collectedQueryParams.join("&");
  }

  // borrowed from https://stackoverflow.com/questions/831030/
  // a function that retrieves the value of a query parameter
  function getQueryParam(name) {
    if (
      (name = new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(
        window.location.search,
      ))
    )
      return decodeURIComponent(name[1]);
  }
})();
